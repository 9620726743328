import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";

import postsReducers from "./reducers/posts";
import userReducers from "./reducers/user";
import App from "./App";
import "./index.css";

const store = configureStore({
  reducer: {
    posts: postsReducers,
    user: userReducers
  }
});
console.log('initial store state:', store.getState());
ReactDOM.render(
  <Provider store={store}>
    <App /> 
  </Provider>, 
  document.getElementById("root")
);