import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Avatar, Button, Paper, Grid, Typography, Container } from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined.js";
import jwt_decode from "jwt-decode";

import { googleSignIn, signIn, signUp } from "../../actions/auth.js";
import Input from "./Input";
import useStyles from "./styles";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: ""
};

const Auth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isSignup, setIsSignup] = useState(false)
  const [formData, setFormData] = useState(initialState);
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("on handle submit form data:", formData);
    if (isSignup) {
      dispatch(signUp({
        email: formData.email,
        password: formData.password,
        name: `${formData.firstName} ${formData.lastName}`
      }, navigate));
    } else {
      dispatch(signIn({userName: formData.email, password: formData.password}, navigate));
    }
  };
  const handleChange = (event) => {
    event.preventDefault();
    setFormData({...formData, [event.target.name]: event.target.value});
  };
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const switchMode = () => {
    setIsSignup(!isSignup);
    setShowPassword(false);
  };
  const classes = useStyles();
  function removeInterval(interval) {
    console.log("clearing interval");
    clearInterval(interval);
  }
  useEffect(()=> {
    const handleGoogleSiginIn = (res) => {
      console.log("handle google sign in:", res);
      var userObject = jwt_decode(res.credential);
      console.log('user object:', userObject);
      const profileImageUrl = userObject.picture;
      const name = userObject.name;
      const email = userObject.email;
      const googleSub = userObject.sub;
      try {
        dispatch(googleSignIn({
          email: email,
          name: name,
          imageUrl: profileImageUrl,
          googleSub: googleSub
        }, navigate));
      } catch (error) {
        console.log('auth error:', error);
      }
    };
    const googleLoadedCheckInterval = setInterval(() => {
      console.log("checking if google is loaded:", window.google != null);
        if (window.google != null) {
          window.google.accounts.id.initialize({
            client_id: "366024504031-sv8crmfpv4v3lb0k7epn99rgr7vdrdo1.apps.googleusercontent.com",
            callback: handleGoogleSiginIn
          });
          window.google.accounts.id.renderButton(
            document.getElementById('googleDiv'),
            {theme: "outlined", size: "large"}
          );
          removeInterval(googleLoadedCheckInterval);
        }
      }, 100
    );
    return () => removeInterval(googleLoadedCheckInterval)
  }, [dispatch, navigate]);

  return (
    <Container component="main" maxWidth="xs">
      <Paper className={classes.paper} elevation={3}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h5">{isSignup ? "Sign Up" : "Sign In"}</Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {
              isSignup && (
                <>
                  <Input name="firstName" label="First Name" handleChange={handleChange} autoFocus half/>
                  <Input name="lastName" label="Last Name" handleChange={handleChange} half/>
                </>
              )
            }
            <Input name="email" label="Email Address" handleChange={handleChange} type="email" />
            <Input name="password" label="Password" handleChange={handleChange} type={showPassword ? "text" : "password"} handleShowPassword={handleShowPassword}/>
            {isSignup && <Input name="confirmPassword" label="Confirm Password" handleChange={handleChange}  type="password" />}
          </Grid>
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            {isSignup ? "Sign Up" : "Log In"}
          </Button>
          <div id="googleDiv" />
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button onClick={switchMode}>
                {isSignup ? "Already have an account? Sign In" : "Don't have an account? Sign Up"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
}

export default Auth;