import { createSlice } from '@reduxjs/toolkit';

const postsSlice = createSlice({
  name: 'posts',
  initialState: {
    isLoading: false,
    data: [],
    currentPage: 0,
    totalPages: 0,
    post: null
  },
  reducers: {
    START_LOADING(state, action) {
      console.log("start loading");
      return {
        ...state,
        isLoading: true
      };
    },
    END_LOADING(state, action) {
      console.log("end loading");
      return {
        ...state,
        isLoading: false
      };
    },
    FETCH_POST(state, action) {
      console.log("fetch post");
      return {
        ...state,
        post: action.payload
      }
    },
    FETCH_ALL(state, action) {
      console.log("fetch all post");
      return {
        ...state,
        data: action.payload.data,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages
      }
    },
    CREATE(state, action) {
      return {
        ...state,
        data:[ ...state.data, action.payload ]
      };
    },
    UPDATE(state, action) {
      const updatedPost = action.payload;
      return {
        ...state,
        data: state.data.map((post) => post._id === updatedPost._id ? updatedPost : post),
      } 
    },
    DELETE(state, action) {
      return {
        ...state,
        data: state.data.filter((post) => post._id !== action.payload)
      };
    }
  }
});

export const { START_LOADING, END_LOADING, FETCH_ALL, FETCH_POST, CREATE, UPDATE, DELETE } = postsSlice.actions;
export default postsSlice.reducer;