import React, { useEffect, useState } from "react";
import { TextField, Button, Typography, Paper } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useAutocomplete } from "@material-ui/lab";
import FileBase from "react-file-base64";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { createPost, updatePost } from "../../actions/posts.js"
import useStyles from "./styles";

const Form = ({currentPostId, setCurrentPostId}) => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => {
    return state.user.profile;
  });
  console.log('form currentUser:', currentUser);
  console.log('form currentPostId:', currentPostId);
  const emptyPostData = {
    creatorName: currentUser?.name || "",
    creatorId: currentUser?._id,
    title: "",
    message: "",
    tags: [],
    selectedFile: "",
  };
  const [postData, setPostData] = useState(emptyPostData);
  const post = useSelector((state) => currentPostId ? state.posts.data.find((p) => p._id === currentPostId) : null);
  useEffect(() => {
    if (post) {
      console.log("form setting post:", post);
      setPostData(post);
    }
  }, [post]);
  const dispatch = useDispatch();
  const handleSubmit = (event) => {
    event.preventDefault();
    if (currentPostId) {
      dispatch(updatePost(currentPostId, postData));
    } else {
      dispatch(createPost(postData, (data) => {
        console.log('create post callback', data);
        navigate(`/post/${data._id}`)
      }));
    }
    setPostData(emptyPostData)
  };
  const clear = () => {
    setCurrentPostId(null);
    setPostData(emptyPostData)
  };

  function Tag(props) {
    const { label, onDelete, ...other } = props;
    return (
      <div {...other}>
        <span>{label}</span>
        <CloseIcon onClick={onDelete} />
      </div>
    );
  }

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    value,
    setAnchorEl,
  } = useAutocomplete({
    id: 'customized-hook-demo',
    defaultValue: [],
    freeSolo: true,
    multiple: true,
    value: postData.tags,
    options: [],
    onChange: (_, newValue) => {
      console.log("auto complete on change", newValue);
      setPostData({...postData, tags:newValue})
    },
  });

  const classes = useStyles();
  if (!currentUser?.name) {
    return (
      <Paper className={classes.paper}>
        <Typography variant="h6" align="center">
          Please Sign In to create your own lobby and like other's lobbies.
        </Typography>
      </Paper>
    );
  }
  return (
    <Paper className={classes.paper}>
      <form 
        autoComplete="off" 
        noValidate 
        className={`${classes.root} ${classes.form}`} 
        onSubmit={handleSubmit}>
        <Typography variant="h6">{currentPostId ? "Update a Lobby" : "Create a Lobby"}</Typography>
        <TextField name="creator" variant="outlined" label="Creator" fullWidth value={postData.creatorName} onChange={(event) => setPostData({ ...postData, creatorName: event.target.value })}/>
        <TextField name="title" variant="outlined" label="Title" fullWidth value={postData.title} onChange={(event) => setPostData({ ...postData, title: event.target.value })}/>
        <TextField name="message" variant="outlined" label="Message" fullWidth value={postData.message} onChange={(event) => setPostData({ ...postData, message: event.target.value })}/>
        <div {...getRootProps()}>
          <Typography className={classes.autoCompleteLabel} {...getInputLabelProps()}>Tags</Typography>
          <div ref={setAnchorEl} className={classes.inputWrapper}>
            {value.map((tag, index) => (
              <Tag className={classes.styledTag} label={tag} {...getTagProps({ index })} />
            ))}
            <input {...getInputProps()}/>
          </div>
        </div>
        <div className={classes.fileInput}>
          <FileBase type="file" multiple={false} onDone={({base64}) => setPostData({...postData, selectedFile: base64})} />
        </div>
        <Button className={classes.buttonSubmit} variant="contained" color="primary" size="large" type="submit" fullWidth> Submit </Button>
        <Button variant="contained" color="secondary" size="small" onClick={clear} fullWidth> Clear </Button>
      </form>
    </Paper>
  )
};

export default Form;
