import React from "react";
import { useSelector } from "react-redux";
import { Grid, CircularProgress, Paper } from "@material-ui/core";

import Post from "./Post/Post";
import useStyles from "./styles";
 
const Posts = ({ setCurrentPostId }) => {
  const posts = useSelector((state) => {
    console.log('posts component state:', state);
    return state.posts
  });
  const classes = useStyles();
  console.log('posts component:', posts);
  return (
    posts.isloading 
    ? <CircularProgress/> 
    : (<Grid className={classes.mainContainer} container alignItems="stretch" spacing={3}>
        {posts.data.map((post) => (
        <Grid key={post._id} item xs={12} sm={12} md={6} lg={3}>
          <Post post={post} setCurrentPostId={setCurrentPostId} />
        </Grid>
      ))}
    </Grid>)
  )
};

export default Posts;
