import { START_LOADING, END_LOADING, FETCH_ALL, FETCH_POST, CREATE, UPDATE, DELETE } from "../reducers/posts";
import * as api from "../api";

export const getPost = (id) => async (dispatch) => {
  try {
    dispatch(START_LOADING());
    console.log("get post with id:", id);
    const { data } = await api.fetchPost(id);
    console.log("fetch post success:", data);
    if (!data) {
      // TODO: Show error!
      return;
    }
    dispatch(FETCH_POST(data))
    dispatch(END_LOADING());
  } catch (error) {
    console.log("get post error", error);
  }
}

export const getPosts = (page) => async (dispatch) => {
  try {
    dispatch(START_LOADING());
    const { data } = await api.fetchPosts(page);
    console.log(`fetch posts page:${page} res:${data}`);
    if (!data) {
      // TODO: Show error!
      return;
    }
    dispatch(FETCH_ALL(data));
    dispatch(END_LOADING());
  } catch (error) {
    console.log("get posts error:", error);
  }  
}

export const searchPosts = (searchQuery) => async (dispatch) => {
  try {
    dispatch(START_LOADING());
    const { data } = await api.searchPosts(searchQuery);
    console.log("search posts res:", data);
    if (!data) {
      // TODO: Show error!
      return;
    }
    dispatch(FETCH_ALL(data));
    dispatch(END_LOADING());
  } catch (error) {
    console.log("search post error:", error);
  }
};

export const createPost = (newPost, callback) => async(dispatch) => {
  try {
    dispatch(START_LOADING());
    const { data } = await api.createPost(newPost);
    console.log("create post res:", data);
    if (!data) {
      // TODO: Show error!
      return;
    }
    dispatch(CREATE(data));
    dispatch(END_LOADING());
    if (callback) {
      callback(data);
    }
  } catch (error) {
    console.log("create post error:", error);
  }
}

export const updatePost = (postId, postData) => async(dispatch) => {
  try {
    const { data } = await api.updatePost(postId, postData);
    console.log("update post res:", data);
    if (!data) {
      // TODO: Show error!
      return;
    }
    dispatch(UPDATE(data));
  } catch (error) {
    console.log("update post error:", error);
  }
}

export const deletePost = (postId) => async(dispatch) => {
  try {
    const { data } = await api.deletePost(postId);
    console.log("delete post res:", data);
    if (!data) {
      // TODO: Show error!
      return;
    }
    dispatch(DELETE(postId));
  } catch (error) {
    console.log("delete post error:", error);
  }
}

export const likePost = (postId) => async(dispatch) => {
  try {
    console.log("liking post:", postId);
    const { data } = await api.likePost(postId);
    console.log("like post res:", data);
    if (!data) {
      // TODO: Show error!
      return;
    }
    dispatch(UPDATE(data));
  } catch (error) {
    console.log("like post error:", error);
  }
}

export const commentPost = (value, postId) => async(dispatch) => {
  try {
    const { data } = await api.commentPost(value, postId);
    console.log("commented post:", data);
    if (!data) {
      // TODO: Show error!
      return;
    }
    dispatch(UPDATE(data));
    return data.comments;
  } catch (error) {
    console.log('comment post error:', error);
  }
}