import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(2),
    borderRadius: 15,
  },
  form: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  fileInput: {
    width: "97%",
    margin: "10px 0",
  },
  buttonSubmit: {
    marginBottom: 10,
  },
  autoCompleteLabel: {
    padding: "0 0 4px",
    lineHeight: 1.5,
    display: "block"
  },
  inputWrapper: {
    border: `1px solid ${theme.palette.mode === 'dark' ? '#434343' : '#d9d9d9'}`,
    backgroundColor: `${theme.palette.mode === 'dark' ? '#141414' : '#fff'}`,
    borderRadius: "4px",
    width: "100%",
    padding: "1px",
    display: "flex",
    flexWrap: "wrap",
    "&:hover":  {
      borderColor: `${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'}`,
    },
    "&.focused": {
      borderColor: `${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'}`,
      boxShadow: "0 0 0 2px rgba(24, 144, 255, 0.2)",
    },
    "& input": {
      backgroundColor: `${theme.palette.mode === 'dark' ? '#141414' : '#fff'}`,
      color: `${
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'
      }`,
      height: "30px",
      boxSizing: "border-box",
      padding: "4px 6px",
      width: "100%",
      minWidth: "30px",
      flexGrow: 1,
      border: 0,
      margin: 0,
      outline: 0,
    }
  },
  styledTag: {
    display: "flex",
    alignItems: "center",
    height: "24px",
    margin: "2px",
    lineHeight: "22px",
    backgroundColor: `${
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.08)' : '#fafafa'
    }`,
    border: `1px solid ${theme.palette.mode === 'dark' ? '#303030' : '#e8e8e8'}`,
    borderRadius: "2px",
    boxSizing: "content-box",
    padding: "0 4px 0 10px",
    outline: 0,
    overflow: "hidden",
    "&:focus": {
      borderColor: `${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'}`,
      backgroundColor: `${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'}`,
    },
    "& span": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    "& svg": {
      fontSize: "12px",
      cursor: "pointer",
      padding: "4px",
    }
  },
  listBox: {
    margin: "2px 0 0",
    padding: 0,
    position: "absolute",
    listStyle: "none",
    backgroundColor: `${theme.palette.mode === 'dark' ? '#141414' : '#fff'}`,
    overflow: "auto",
    maxHeight: "250px",
    borderRadius: "4px",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
    zIndex: 1,
    "& li": {
      padding: "5px 12px",
      display: "flex",
      "& span": {
        flexGrow: 1,
      },
      "& svg": {
        color: "transparent",
      }
    },
    "& li[aria-selected='true']": {
      backgroundColor: `${theme.palette.mode === 'dark' ? '#2b2b2b' : '#fafafa'}`,
      fontWeight: 600,
      "& svg": {
        color: "#1890ff",
      }
    },
    // eslint-disable-next-line
    "& li.${autocompleteClasses.focused}": {
      backgroundColor: `${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'}`,
      cursor: "pointer",
      "& svg": {
        color: "currentColor",
      }
    }
  }
}));