import React from "react";
import { Card, CardActions, CardContent, CardMedia, Button, ButtonBase, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ThumbUpAltOutlined from "@material-ui/icons/ThumbUpAltOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import moment from "moment";

import { deletePost, likePost } from "../../../actions/posts.js";
import useStyles from "./styles";

const Post = ({ post, setCurrentPostId }) => {
  const currentUser = useSelector((state) => state.user.profile);
  const currentUserId = currentUser ? currentUser._id : null;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const getLikeString = (number) => {
    return number === 1 ? "Like" : "Likes"
  };
  const Likes = () => {
    if (post.likes.length > 0) {
      return post.likes.find((like) => like === currentUserId)
      ? (
        <>
          <ThumbUpAltIcon fontSize="small" />&nbsp;{post.likes.length > 2 ? `You and ${post.likes.length - 1} other ${getLikeString(post.likes.length - 1)}` : `${post.likes.length} ${getLikeString(post.likes.length)}`}
        </>
      ) : (
        <>
          <ThumbUpAltOutlined fontSize="small" />&nbsp;{post.likes.length} {getLikeString(post.likes.length)}
        </>
      );
    }
    return <><ThumbUpAltOutlined fontSize="small"/>&nbsp; Like</>
  };

  return (
    <Card className={classes.card} raised elevation={6}>
      <ButtonBase
        component="span"
        name="test"
        className={classes.cardAction}
        onClick={(e) => {
          // dispatch(getPost(post._id, history));
          navigate(`/post/${post._id}`);
        }}
      >
        { post.selectedFile !== "" 
          ? <CardMedia className={classes.media} image={post.selectedFile} title={post.title}/>
          : <div className={classes.media} />}
        <div className={classes.overlay}>
          <Typography variant="h6">{post.creatorName}</Typography>
          <Typography variant="body2">{moment(post.createdAt).fromNow()}</Typography>
        </div>
        <div className={classes.overlay2}>
          {currentUser?._id === post.creatorId &&
            <Button 
              style={{color: "white"}} 
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                console.log("post learn more tapped", post._id);
                setCurrentPostId(post._id)
              }}>
              <MoreHorizIcon fontSize="medium" />
            </Button>
          }
        </div>
        <div className={classes.details}>
          <Typography variant="body2">{post.tags.map((tag) => `#${tag} `)}</Typography>
        </div>
        <Typography className={classes.title} variant="h5" gutterBottom>{post.title}</Typography>
        <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">{post.message}</Typography>
        </CardContent>
      </ButtonBase>
      <CardActions className={classes.cardActions}>
        <Button 
          size="small" 
          color="primary"
          disabled={!currentUser}
          onClick={() => {
            dispatch(likePost(post._id));
          }}>
          <Likes />
        </Button>
        {
          currentUser?._id === post.creatorId &&
          <Button 
            size="small" 
            color="primary"
            onClick={() => {
              dispatch(deletePost(post._id));
            }}>
            <DeleteIcon fontSize="small" />
            Delete
          </Button>
        }
      </CardActions>
    </Card>
  )
};

export default Post;
