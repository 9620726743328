import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Container, Grow, Grid, Paper, AppBar, TextField, Button } from "@material-ui/core";
import { useNavigate, useLocation } from "react-router-dom";

import { searchPosts } from "../../actions/posts";
import Form from "../../components/Form/Form";
import Posts from "../../components/Posts/Posts";
import Pagination from "../../components/Pagination/Pagination";
import useStyles from "./styles";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Home = () => {
  const [currentPostId, setCurrentPostId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const page = query.get('page') || 1;
  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      search(event);
    }
  }

  const search = (event) => {
    event.preventDefault();
    console.log(`search posts with search term:${searchTerm}`)
    if (searchTerm.trim()) {
      dispatch(searchPosts({
        searchTerm,
        page
      }))
    } else {
      navigate("/");
    }
  }

  const classes = useStyles();
  return (
    <Grow in>
      <Container maxWidth="xl">
        <Grid className={classes.gridContainer} container justifyContent="space-between" alignItems="stretch" spacing={3}>
          <Grid item xs={12} sm={6} md={9}>
            <Posts setCurrentPostId={setCurrentPostId}/>
            <Paper elevation={6}>
              <Pagination page={page} />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <AppBar className={classes.appBarSearch} position="static" color="inherit">
              <TextField 
                name="search"
                variant="outlined" 
                label="Search Tee Times"
                fullWidth
                value={searchTerm}
                onKeyDown={handleKeyDown}
                onChange={(event) => {
                  setSearchTerm(event.target.value);
                }} />
              <Button onClick={search} className={classes.searchButton} variant="contained" color="primary">Search</Button>
            </AppBar>
            <Form currentPostId={currentPostId} setCurrentPostId={setCurrentPostId}/> 
          </Grid>
        </Grid>
      </Container>
    </Grow>
  )
};

export default Home;