import axios from "axios";

const API = axios.create({ baseURL: "https://tappy-golf.herokuapp.com/"});

// user token
API.interceptors.request.use((req) => {
  const currentUserString = localStorage.getItem("user");
  console.log("current user string:", currentUserString);
  const currentUser = JSON.parse(currentUserString);
  console.log("current user:", currentUser);
  if (currentUser) {
    req.headers.Authorization = `Bearer ${currentUser.token}`
  }
  return req;
});

// posts
export const fetchPost = (postId) => API.get(`/posts/${postId}`);
export const fetchPosts = (page) => API.get(`/posts?page=${page}`);
export const searchPosts = (searchQuery) => API.get(`/posts/search?searchTerm=${searchQuery.searchTerm || "none"}&tags=${searchQuery.tags}&page=${searchQuery.page}`)
export const createPost = (newPost) => API.post("/posts", newPost);
export const updatePost = (postId, postData) => API.patch(`/posts/${postId}`, postData);
export const deletePost = (postId) => API.delete(`posts/${postId}`);
export const likePost = (postId) => API.patch(`posts/${postId}/like`);
export const commentPost = (value, postId) => API.patch(`posts/${postId}/comment`, {value});
// users
export const signIn = (loginData) => axios.post(`/user/signIn`, loginData);
export const signUp = (userData) => axios.post(`/user/signUp`, userData);
export const googleSignIn = (googleData) => {
  console.log("google sign in:", googleData);
  return axios.post(`/user/googleSignIn`, googleData);
}