import React, { useState, useRef } from 'react';
import { Typography, TextField, Button } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import useStyles from "./styles";
import { commentPost } from "../../actions/posts";

const CommentSection = ({ post }) => {
  console.log('rendering comment section!');
  const classes = useStyles();
  const dispatch = useDispatch();
  const commentsRef = useRef();
  const currentUser = useSelector((state) => {
    return state.user.profile;
  });
  const [comments, setComments] = useState(post.comments);
  const [comment, setComment] = useState("");
  const handleClick = async (event) => {
    const updatedComments = await dispatch(commentPost(comment, post._id));
    setComments(updatedComments);
    setComment("");
    commentsRef.current.scrollIntoView({behavior:"smooth"});
  };
  
  return (
    <div>
      <div className={classes.commentsOuterContainer}>
        <div className={classes.commentsInnerContainer}>
          <Typography gutterBottom variant="h6">Comments</Typography>
          {
            comments.map((postComment, index) => (
              <Typography key={index} gutterBottom variant="subtitle1">
                {postComment}
              </Typography>
            ))
          }
          <div ref={commentsRef} />
        </div>
        {
          currentUser && (
            <div style={{ width: "70%" }}>
              <Typography gutterBottom variant="h6">Write a Comment</Typography>
              <TextField 
                fullWidth
                minRows={4}
                variant="outlined"
                label="Comment"
                multiline
                value={comment}
                onChange={(event) => {
                  setComment(event.target.value);
                }}
              />
              <Button 
                style={{ marginTop:"10px"}} 
                fullWidth disabled={!comment} 
                variant="contained"
                color="primary"
                onClick={handleClick}>
                Comment
              </Button>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default CommentSection;