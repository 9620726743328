import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPosts } from "../../actions/posts";
import { Pagination, PaginationItem } from '@material-ui/lab';
import { Link } from "react-router-dom";

import useStyles from "./styles";

const Paginate = ({ page }) => {
  const dispatch = useDispatch();
  const { totalPages } = useSelector((state) => {
    console.log('pagination component state:', state);
    return state.posts
  });
  useEffect(() => {
    if (page) dispatch(getPosts(page))
  }, [page, dispatch]);

  const classes = useStyles();
  return (
    <Pagination 
      classes={{ ul: classes.ul }}
      count={totalPages}
      page={Number(page)}
      variant="outlined"
      color="primary"
      renderItem={(item) => (
        <PaginationItem {...item} component={ Link } to={`/posts?page=${item.page}`}/>
      )}
    />
  )
};

export default Paginate;