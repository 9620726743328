import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {},
  reducers: {
    SIGNIN(state, action) {
      console.log("sign in reducer, action:", action);
      const userResult = action.payload;
      if (!userResult) {
        throw new Error('userProfile is missing in sign in reducer');
      }
      localStorage.setItem("user", JSON.stringify(userResult));
      return {profile: userResult.profile}
    },
    SIGNOUT(state, action) {
      console.log("sign out reducer, action:", action);
      localStorage.removeItem("user");
      return {profile: null};
    },
  }
});

export const { SIGNIN, SIGNOUT } = userSlice.actions;
export default userSlice.reducer;