import { SIGNIN, SIGNOUT } from "../reducers/user";
import * as api from "../api";

export const googleSignIn = ({email, name, imageUrl, googleSub}, navigate) => async (dispatch) => {
  try {
    const returnData = await api.googleSignIn({
      email,
      name,
      imageUrl,
      googleSub
    })
    console.log("google sign in return data:", returnData);
    if (!returnData) {
      // TODO: Show error!
    }
    dispatch(SIGNIN(returnData.data));
    navigate("/");
  } catch (error) {
    console.log(error);
  }
}

export const signIn = ({userName, password}, navigate) => async (dispatch) => {
  try {
    const returnData = await api.signIn({
      userName,
      password
    });
    console.log("sign in return data:", returnData);
    if (!returnData) {
      // TODO: Show error!
      return;
    }
    dispatch(SIGNIN(returnData.data));
    navigate("/");
  } catch (error) {
    console.log(error);
  }
}

export const signUp = ({email, password, name}, navigate) => async (dispatch) => {
  try {
    const userData = {email, password, name};
    const returnData = await api.signUp(userData);
    console.log("sign up return data:", returnData);
    if (!returnData) {
      // TODO: Show error!
      return;
    }
    dispatch(SIGNIN(returnData.data))
    navigate("/");
  } catch (error) {
    console.log(error);
  }  
}

export const signOut = () => async (dispatch) => {
  try {
    dispatch(SIGNOUT());
  } catch (error) {
    console.log(error);
  }  
}